import { notification } from 'antd';

export const getErrorMessage = (error) => {
  const responseMessage = error.response ? error.response.data.message : null;
  const message = responseMessage || error.message;
  return message;
};

export const notificationError = (error) => {
  notification.error({
    message: 'Error',
    description: getErrorMessage(error),
    duration: 0,
  });
};
