import axios from 'axios';
import { API_URL } from '../app/config';
import { removeEmptyValues } from '../common/utils';

const createUrl = (url) => `${API_URL}/fillingOrdersAPI/${url}`;

const config = {
  data: {},
  auth: {
    username: 'root',
    password: 'root',
  },
};

const getOrders = (page, pageSize, filters) => (
  axios.post(
    createUrl(`order/orders/page/${page}`),
    {
      limit: pageSize,
      filters: removeEmptyValues({ ...filters, cutStatus: 'NUEVA' }),
      orderBy: 'createdAt',
      desc: true,
    },
    config,
  )
    .then((response) => response.data.data)
);

const getOrder = (orderFolio) => (
  axios.get(createUrl(`order/${orderFolio}`), config)
    .then((response) => response.data.data)
);

const getReceptionUnits = (customerCode) => (
  axios.get(createUrl(`catalogs/customer/${customerCode}/receptionUnits`), config)
    .then((response) => response.data.data)
);

const getCustomers = () => (
  axios.get(createUrl('catalogs/customers'), config)
    .then((response) => response.data.data)
);

const createOrder = (codeUdr, authorizedKilos, receipt, idUser, cutFolio, customerCode) => (
  axios.post(
    createUrl('order/orders'),
    {
      codeUdr,
      authorizedKilos,
      receipt,
      idUser,
      cutFolio,
      customerCode,
    }, config,
  )
    .then((response) => response.data.data)
);

const cancelOrder = (orderFolio, reason) => (
  axios.post(createUrl(`order/cancel/${orderFolio}`), { reason }, config)
    .then((response) => response.data.data)
);

const unblockOrder = (orderFolio) => (
  axios.post(createUrl(`order/unblock/${orderFolio}`), {}, config)
    .then((response) => response.data.data)
);
const syncOrder = (orderFolio) => (
  axios.post(createUrl(`order/sync/${orderFolio}`), {}, config)
    .then((response) => response.data.data)
);

const finishOrder = (orderFolio, reason) => (
  axios.post(createUrl(`order/finish/${orderFolio}`), { reason }, config)
    .then((response) => response.data.data)
);

const login = (user, password, customerCode) => (
  axios.post(createUrl('order/login'), { user, password, customerCode }, config)
    .then((response) => response.data.data)
);

const cut = (userId) => (
  axios.get(createUrl(`order/cuts/${userId}`), config)
    .then((response) => response.data.data)
);

const getEquipments = (customerCode) => (
  axios.get(createUrl(`catalogs/customer/${customerCode}/equipments`), config)
    .then((response) => response.data.data)
);
const updateFillingOrderBalance = (fillingOrderValid) => (
  axios.post(createUrl(`order/validateAndUpdateBalance/${fillingOrderValid.folio}`), { ...fillingOrderValid }, config)
    .then((response) => response.data.data));

export default {
  getReceptionUnits,
  createOrder,
  getOrders,
  cancelOrder,
  unblockOrder,
  syncOrder,
  finishOrder,
  getOrder,
  login,
  cut,
  getEquipments,
  getCustomers,
  updateFillingOrderBalance,
};
