import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const NivoResponsivePie = ({ data }) => (
  <ResponsivePie
    data={data}
    margin={{
      top: 20, right: 20, bottom: 20, left: 20,
    }}
    startAngle={0}
    padAngle={0}
    cornerRadius={0}
    colors={{ scheme: 'nivo' }}
    borderWidth={0}
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    radialLabelsSkipAngle={10}
    radialLabelsTextXOffset={6}
    radialLabelsTextColor="#333333"
    radialLabelsLinkOffset={0}
    radialLabelsLinkDiagonalLength={16}
    radialLabelsLinkHorizontalLength={24}
    radialLabelsLinkStrokeWidth={1}
    radialLabelsLinkColor={{ from: 'color' }}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor="#333333"
    animate
    motionStiffness={90}
    motionDamping={15}
  />
);

export default NivoResponsivePie;
