import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Descriptions, Badge, Spin } from 'antd';
import { fetchOrder } from './orderDetailsSlice';
import { OrderColors } from '../../common/constants';

const OrderDetails = () => {
  const { orderFolio } = useParams();
  const dispatch = useDispatch();
  const { order, loading } = useSelector((state) => state.orderDetails);

  useEffect(() => {
    dispatch(fetchOrder(orderFolio));
  }, [dispatch, orderFolio]);

  if (loading) {
    return <Spin spinning style={{ width: '100%' }} />;
  }

  return (
    <div>
      <Descriptions title={`Folio: ${order.orderFolio}`} bordered>
        <Descriptions.Item label="Estatus" span={order.canceledAt ? 2 : 3}>
          <Badge status={OrderColors[order.status]} text={order.status} />
        </Descriptions.Item>
        {
          order.canceledAt && (
            <Descriptions.Item label="Fecha de cancelación">
              {moment(order.canceledAt).format('DD/MM/YYYY - hh:mm a')}
            </Descriptions.Item>
          )
        }
        <Descriptions.Item label="Cliente" span={2}>{order.customerCode}</Descriptions.Item>
        <Descriptions.Item label="Fecha">
          {moment(order.createdAt).format('DD/MM/YYYY - hh:mm a')}
        </Descriptions.Item>
        <Descriptions.Item label="Kilos autorizados" span={2}>
          {order.authorizedKilos}
        </Descriptions.Item>
        <Descriptions.Item label="Kilos llenados">{order.kilosFilled}</Descriptions.Item>
        <Descriptions.Item label="Precio" span={2}>{order.price}</Descriptions.Item>
        <Descriptions.Item label="Descuento">{order.discount}</Descriptions.Item>
        <Descriptions.Item label="Código UDR" span={2}>{order.codeUdr}</Descriptions.Item>
        <Descriptions.Item label="Condición de pago">{order.paymentCondition}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default OrderDetails;
