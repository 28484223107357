import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'antd';
import LoginForm from '../../components/loginForm';
import { loginAuth } from '../../common/commonSlice';

const Login = () => {
  const { invalidLogin, authLoading } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const handleLogin = ({ email, password, customerCode }) => {
    dispatch(loginAuth(email, password, customerCode));
  };

  return (
    <Row
      justify="space-around"
      align="middle"
      style={{ height: '100vh', background: '#001529' }}
    >
      <LoginForm
        onSubmit={handleLogin}
        invalidLogin={invalidLogin}
        loading={authLoading}
      />
    </Row>
  );
};

export default Login;
