import React from 'react';
import moment from 'moment';
import './styles.css';
import { toNumberFormat } from '../../common/utils';
import { OrderAcronym } from '../../common/constants';

class CutTicket extends React.Component { // eslint-disable-line
  render() {
    const { orders } = this.props;
    let sumTotal = 0;

    if (orders.length < 1) {
      return (<div>--- No se encontraron órdenes en este corte ---</div>);
    }

    return (
      <div className="ticket__container">
        <div className="ticket__header">
          <div className="header__brand">{`Corte del ${moment().format('DD/MM/YYYY')}`}</div>
        </div>
        <table>
          <tbody>
            <tr>
              <th className="first_column bold">Fo</th>
              <th className="next_columns bold">KG</th>
              <th className="next_columns bold">To</th>
              <th className="next_columns bold">St</th>
              <th className="next_columns bold">Fe</th>
            </tr>
            {orders.map((order) => {
              const total = order.total - order.customerBalance;
              sumTotal += total;
              return (
                <tr key={order.orderFolio}>
                  <td className="first_column">{order.orderFolio}</td>
                  <td className="next_columns">{toNumberFormat(order.kilosFilled)}</td>
                  <td className="next_columns">{`$${toNumberFormat(total)}`}</td>
                  <td className="next_columns">{`${OrderAcronym[order.status] || 'NA'}`}</td>
                  <td className="next_columns">{`${moment(order.createdAt).format('DD/MM-hh:mma')}`}</td>
                </tr>
              );
            })}
            <tr>
              <td colSpan="3">------------</td>
            </tr>
            <tr>
              <td colSpan="3">{`Folios: ${toNumberFormat(orders.length)}`}</td>
            </tr>
            <tr>
              <td colSpan="3">
                {`Kilos: ${toNumberFormat(orders.reduce((acc, o) => o.kilosFilled + acc, 0))}KG`}
              </td>
            </tr>
            <tr>
              <td colSpan="3">{`Total: $${toNumberFormat(sumTotal)}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default CutTicket;
