import { createSlice } from '@reduxjs/toolkit';
import FillingOrdersApi from '../../services/fillingOrdersApi';

export const slice = createSlice({
  name: 'orderDetails',
  initialState: {
    order: {},
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    fetchOrderSuccess: (state, action) => {
      state.order = action.payload;
    },
  },
});

export const {
  fetchOrderSuccess, setLoading,
} = slice.actions;

export const fetchOrder = (orderFolio) => (dispatch) => {
  dispatch(setLoading(true));
  FillingOrdersApi.getOrder(orderFolio)
    .then((data) => {
      dispatch(fetchOrderSuccess(data));
      dispatch(setLoading(false));
    })
    .catch(() => {
      dispatch(setLoading(false));
    });
};

export default slice.reducer;
