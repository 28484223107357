import { configureStore } from '@reduxjs/toolkit';
import commonReducer from '../common/commonSlice';
import fillingOrdersReducer from '../routes/fillingOrders/fillingOrdersSlice';
import orderDetailsReducer from '../routes/orderDetails/orderDetailsSlice';
import dashboardReducer from '../routes/dashboard/dashboardSlice';

export default configureStore({
  reducer: {
    common: commonReducer,
    fillingOrders: fillingOrdersReducer,
    orderDetails: orderDetailsReducer,
    dashboard: dashboardReducer,
  },
});
