import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import { groupBy } from 'lodash';
import { NivoResponsiveBar, NivoResponsivePie, NivoResponsiveLine } from '../../components/nivo';
import ChartContainer from '../../components/chartContainer';
import { OrderStatus, OrderColorsHex } from '../../common/constants';
import { getOrderTotal } from '../../common/utils';
import { fetchOrders } from './dashboardSlice';

const getStatusData = (orders) => (
  Object.keys(OrderStatus).map((k) => ({
    id: k.toLowerCase(),
    value: orders.filter((i) => i.status === k).length,
    color: OrderColorsHex[k].borderColor,
  }))
);

const getPaymentConditionData = (orders) => {
  const paymentConditions = groupBy(orders, 'paymentCondition');
  return Object.keys(paymentConditions).map((k) => ({
    id: k, value: paymentConditions[k].length,
  }));
};

const getKilosData = (orders) => ([
  { id: 'Kilos autorizados', value: orders.reduce((acc, i) => i.authorizedKilos + acc, 0) },
  { id: 'Kilos llenados', value: orders.reduce((acc, i) => i.kilosFilled + acc, 0), color: '#b7eb8f' },
]);

const getSales = (orders, date) => ([
  {
    id: 'Venta autorizada',
    data: [
      { x: '', y: 0 },
      {
        x: date,
        y: orders.reduce((acc, i) => (
          (i.price - i.discount) * i.authorizedKilos + acc
        ), 0),
      },
    ],
  },
  {
    id: 'Venta real',
    data: [
      { x: '', y: 0 },
      { x: date, y: orders.reduce((acc, i) => getOrderTotal(i) + acc, 0) },
    ],
  },
]);

const Dashboard = () => {
  const { filters, loading, orders } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const kilosData = getKilosData(orders);
  const status = getStatusData(orders);
  const paymentConditions = getPaymentConditionData(orders);
  const sales = getSales(orders, filters.date);

  useEffect(() => {
    dispatch(fetchOrders(0, 10000, {
      folio: null,
      status: null,
    }));
  }, [dispatch, filters]);

  /*  const onChangeFilter = (filter, value) => {
    const updatedFilters = {
      ...filters,
      [filter]: value,
    };

    dispatch(setFilters(updatedFilters));
  };
*/
  return (
    <div>
      <Row style={{ margin: '10px' }}>
        <Col flex="auto">
          <div style={{ fontSize: '22px' }}>Tablero</div>
        </Col>

      </Row>
      <Row type="flex">
        <Col xs={24} sm={24} xl={12}>
          <ChartContainer title="Frecuencia de estatus" loading={loading} empty={orders.length === 0}>
            <NivoResponsiveBar data={status} />
          </ChartContainer>
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <ChartContainer title="Totalizado de kilos" loading={loading} empty={orders.length === 0}>
            <NivoResponsiveBar data={kilosData} />
          </ChartContainer>
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <ChartContainer title="Ventas" loading={loading} empty={orders.length === 0}>
            <NivoResponsiveLine data={sales} />
          </ChartContainer>
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <ChartContainer title="Ventas por condición de pago" loading={loading} empty={orders.length === 0}>
            <NivoResponsivePie data={paymentConditions} />
          </ChartContainer>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
