import { createSlice } from '@reduxjs/toolkit';
import { saveUserData } from './localStorage';
import FillingOrdersApi from '../services/fillingOrdersApi';

export const slice = createSlice({
  name: 'common',
  initialState: {
    invalidLogin: false,
    authLoading: false,
    equipments: [],
    customers: [],
  },
  reducers: {
    setAuthLoading: (state, action) => {
      state.authLoading = action.payload;
    },
    loginSuccess: (state) => {
      state.invalidLogin = false;
    },
    loginFailed: (state) => {
      state.invalidLogin = true;
    },
    fetchEquipmentsSuccess: (state, action) => {
      state.equipments = action.payload
    },
    fetchEquipmentsFailed: (state) => {
      state.equipments = []
    },
    fetchCustomersSuccess: (state, action) => {
      state.customers = action.payload
    },
    fetchCustomersFailed: (state) => {
      state.customers = []
    },
  },
});

export const {
  setAuthLoading,
  loginSuccess,
  loginFailed,
  fetchEquipmentsSuccess,
  fetchEquipmentsFailed,
  fetchCustomersSuccess,
  fetchCustomersFailed,
} = slice.actions;

export const loginAuth = (username, password, customerCode) => (dispatch) => {
  dispatch(setAuthLoading(true));
  FillingOrdersApi.login(username, password, customerCode)
    .then((data) => {
      saveUserData(data);
      dispatch(loginSuccess());
      dispatch(setAuthLoading(false));
      window.location.replace('/');
    })
    .catch(() => {
      dispatch(loginFailed());
      dispatch(setAuthLoading(false));
    });
};

export const fetchEquipments = (customerCode) => (dispatch) => {FillingOrdersApi.getEquipments(customerCode)
    .then((data) => {
      dispatch(fetchEquipmentsSuccess(data))
    })
    .catch((error) => {
      dispatch(fetchEquipmentsFailed())
    });
};

export const fetchCustomers = () => (dispatch) => {
  FillingOrdersApi.getCustomers()
  .then((data) => {
    dispatch(fetchCustomersSuccess(data))
  })
  .catch((error) => {
    dispatch(fetchCustomersFailed())
  })
}

export default slice.reducer;
