import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Layout,
  Menu,
  Avatar,
  Row,
  Col,
  Dropdown,
  Button,
} from 'antd';
import {
  LogoutOutlined,
  ScissorOutlined,
  HomeOutlined,
  FireOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { fetchCsvData, fetchOrders, setCutOrdersToPrint } from '../../routes/fillingOrders/fillingOrdersSlice';
import { getUserData, clearLocalStorage } from '../../common/localStorage';
import { version } from '../../../package.json';
import './styles.css';
import FillingOrdersApi from '../../services/fillingOrdersApi';
import CutTicket from '../cutTicket';

const { Header, Content, Sider } = Layout;

const MainLayout = ({ title, children, contentStyle }) => {
  const history = useHistory();
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleMenuClick = (e) => {
    if (e.key.length > 0) {
      history.push(e.key);
    }
  };

  const cutTicketToPrint = useRef();
  const handlePrintCutTicket = useReactToPrint({
    content: () => cutTicketToPrint.current,
  });

  const { cutToPrint } = useSelector((state) => state.fillingOrders);

  const handleCsvDownload = () => {
    dispatch(fetchCsvData({}))
      .then(async (data) => {
        // await generateCSV(data, columnsDataCSV, ',');
        await dispatch(setCutOrdersToPrint(data));
        await handlePrintCutTicket();
        await FillingOrdersApi.cut(userData.idUser);
        await dispatch(fetchOrders(0, 10, { folio: null, status: null }));
      }).catch((error) => {
        throw error;
      });
  };

  const handleLogOut = () => {
    clearLocalStorage();
    window.location.replace('/login');
  };

  const userMenu = (
    <Menu>
      <Menu.Item icon={<ScissorOutlined />} onClick={handleCsvDownload}>
        Corte Caja
      </Menu.Item>
      <Menu.Item key="logOut" onClick={handleLogOut} icon={<LogoutOutlined />}>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="layout">
      <Sider
        breakpoint="lg"
        collapsible
      >
        <div className="sider__logo" />
        <Menu
          theme="dark"
          mode="inline"
          onClick={handleMenuClick}
          selectedKeys={[`/${location.pathname.split('/')[1]}`]}
        >
          <Menu.Item key="/" icon={<HomeOutlined />}>
            Tablero
          </Menu.Item>
          <Menu.Item key="/orders" icon={<FireOutlined />}>
            Ordenes de llenado
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header>
          <Row type="flex">
            <Col xs={4} sm={2} md={2} />
            <Col xs={10} sm={14} md={16} className="layout__title">{title}</Col>
            <Col xs={10} sm={8} md={6} className="layout__user-section">
              <div className="user-section__name">
                {userData.fullName}
              </div>
              <div>
                <Dropdown overlay={userMenu} placement="bottomRight" trigger={['click']}>
                  <Button type="text">
                    <Avatar icon={<UserOutlined />} />
                  </Button>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Header>
        <Content className="layout__content" style={{ ...contentStyle }}>
          {children}
          <div className="layout__version">{`v ${version}`}</div>
          <div style={{ display: 'none' }}>
            <CutTicket ref={cutTicketToPrint} orders={cutToPrint} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
