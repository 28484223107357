import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const NivoResponsiveLine = ({ data }) => (
  <ResponsiveLine
    data={data}
    margin={{
      top: 10, right: 50, bottom: 50, left: 50,
    }}
    enableSlices="x"
    yScale={{
      type: 'linear',
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
    }}
    legends={[
      {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 50,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
      },
    ]}
  />
);

export default NivoResponsiveLine;
