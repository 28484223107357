import { createSlice } from '@reduxjs/toolkit';
import { notificationError } from '../../common/errors';
import FillingOrdersApi from '../../services/fillingOrdersApi';
import { getUserData } from '../../common/localStorage';
import { calculateTotalByKilos } from '../../common/utils';

const defaultFilters = {
  folio: null,
  status: null,
};

export const slice = createSlice({
  name: 'fillingOrders',
  initialState: {
    receptionUnits: [],
    orders: [],
    loading: false,
    loadingCSV: false,
    loadingPrint: false,
    showOrderForm: false,
    finishOrderFolio: null,
    cancelOrderFolio: null,
    creatingOrder: false,
    order: null,
    folioValue: null,
    filters: { ...defaultFilters },
    pagination: {
      page: 0,
      limit: 10,
      totalItems: 0,
    },
    totals: {
      folios: 0,
      total: 0,
      totalKgFilling: 0,
    },
    orderWithRefund: {},
    showRefundForm: false,
    fillingOrder: [],
    cutToPrint: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingCSV: (state, action) => {
      state.loadingCSV = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.pagination.page = 0;
    },
    resetFilters: (state) => {
      state.filters = { ...defaultFilters };
      state.folioValue = null;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setFolioValue: (state, action) => {
      state.folioValue = action.payload;
    },
    fetchReceptionUnitsSuccess: (state, action) => {
      state.receptionUnits = action.payload;
    },
    fetchReceptionUnitsFailed: (state) => {
      state.receptionUnits = [];
    },
    fetchOrdersSuccess: (state, action) => {
      state.orders = action.payload.items;
      state.pagination.totalItems = action.payload.info.totalItems;
      state.totals = action.payload.info.totals;
    },
    createOrderSuccess: (state, action) => {
      state.order = action.payload;
    },
    toggleCreatingOrder: (state, action) => {
      state.creatingOrder = action.payload;
    },
    toggleOrderForm: (state, action) => {
      state.showOrderForm = action.payload;
    },
    setFinishOrderFolio: (state, action) => {
      state.finishOrderFolio = action.payload;
    },
    setCancelOrderFolio: (state, action) => {
      state.cancelOrderFolio = action.payload;
    },
    setLoadingPrint: (state, action) => {
      state.loadingPrint = action.payload;
    },
    toggleOrderRefund: (state, action) => {
      state.orderWithRefund = action.payload.order;
      state.showRefundForm = action.payload.isVisible;
    },
    fetchFillingOrderValid: (state, action) => {
      state.fillingOrder = action.payload;
    },
    setCutToPrint: (state, action) => {
      state.cutToPrint = action.payload;
    },
  },
});

export const {
  fetchReceptionUnitsSuccess, fetchReceptionUnitsFailed, setLoading, toggleOrderForm,
  createOrderSuccess, toggleCreatingOrder, fetchOrdersSuccess, setFilters, resetFilters,
  setPagination, setFolioValue, setLoadingCSV, setFinishOrderFolio, setLoadingPrint,
  setCancelOrderFolio, toggleOrderRefund, fetchFillingOrderValid, setCutToPrint,
} = slice.actions;

export const fetchOrders = (page, pageSize, filters) => (dispatch) => {
  const { idUser } = getUserData();
  dispatch(setLoading(true));
  return FillingOrdersApi.getOrders(page, pageSize, { ...filters, idUser })
    .then((data) => {
      dispatch(fetchOrdersSuccess(data));
      dispatch(setLoading(false));
    })
    .catch((error) => {
      notificationError(error);
      dispatch(setLoading(false));
    });
};

export const fetchReceptionUnits = (customerCode) => (dispatch) => {
  FillingOrdersApi.getReceptionUnits(customerCode)
    .then((data) => {
      dispatch(fetchReceptionUnitsSuccess(data));
    })
    .catch((error) => {
      notificationError(error);
      dispatch(fetchReceptionUnitsFailed());
    });
};

export const createOrder = (codeUdr, kilos, total, receipt, customerCode) => (dispatch) => {
  dispatch(toggleCreatingOrder(true));
  const userData = getUserData();
  return FillingOrdersApi.createOrder(
    codeUdr,
    kilos,
    receipt,
    userData.idUser,
    userData.cutFolio,
    customerCode,
  ).then((data) => {
    dispatch(createOrderSuccess({
      ...data,
      kilos,
      total,
      receipt,
      customerCode,
    }));
    dispatch(toggleOrderForm(false));
    dispatch(toggleCreatingOrder(false));
  })
    .catch((error) => {
      notificationError(error);
      dispatch(toggleCreatingOrder(false));
      throw error;
    });
};

export const cancelOrder = (orderFolio, reason) => (dispatch) => {
  dispatch(setLoading(true));
  return FillingOrdersApi.cancelOrder(orderFolio, reason)
    .then(() => dispatch(setLoading(false)))
    .catch((error) => {
      dispatch(setLoading(false));
      throw error;
    });
};

export const unblockOrder = (orderFolio) => (dispatch) => {
  dispatch(setLoading(true));
  return FillingOrdersApi.unblockOrder(orderFolio)
    .then(() => dispatch(setLoading(false)))
    .catch((error) => {
      dispatch(setLoading(false));
      throw error;
    });
};
export const syncOrder = (orderFolio) => (dispatch) => {
  dispatch(setLoading(true));
  return FillingOrdersApi.syncOrder(orderFolio)
    .then(() => dispatch(setLoading(false)))
    .catch((error) => {
      dispatch(setLoading(false));
      throw error;
    });
};

export const finishOrder = (orderFolio, reason) => (dispatch) => {
  dispatch(setLoading(true));
  return FillingOrdersApi.finishOrder(orderFolio, reason)
    .then(() => dispatch(setLoading(false)))
    .catch((error) => {
      notificationError(error);
      dispatch(setLoading(false));
      throw error;
    });
};

export const fetchCsvData = (filters) => (dispatch) => {
  dispatch(setLoadingCSV(true));
  const { idUser } = getUserData();
  return FillingOrdersApi.getOrders(0, 10000, { ...filters, idUser })
    .then((data) => {
      dispatch(setLoadingCSV(false));
      return data.items;
    })
    .catch((error) => {
      notificationError({ message: 'No se pudo generar el archivo' });
      dispatch(setLoadingCSV(false));
      throw error;
    });
};

export const setOrdertoPrint = (order) => (dispatch) => {
  dispatch(setLoadingPrint(true));
  const total = calculateTotalByKilos(order.price, order.discount, order.kilos);
  return new Promise((resolve) => {
    resolve(dispatch(createOrderSuccess({ ...order, total })));
  })
    .then(() => {
      dispatch(setLoadingPrint(false));
    }).catch((error) => {
      dispatch(setLoadingPrint(false));
      throw error;
    });
};

export const fetchUpdateFillingOrderBalance = (fillingOrderValid) => (dispatch) => {
  dispatch(setLoading(true));
  return FillingOrdersApi.updateFillingOrderBalance(fillingOrderValid)
    .then((data) => {
      dispatch(fetchFillingOrderValid(data));
      dispatch(setLoading(false));
    })
    .catch((error) => {
      notificationError(error);
      dispatch(setLoading(false));
      throw error;
    });
};

export const setCutOrdersToPrint = (cutToPrint) => (dispatch) => {
  dispatch(setLoading(true));
  return new Promise((resolve) => {
    resolve(dispatch(setCutToPrint(cutToPrint)));
  }).then(() => dispatch(setLoading(false))).catch((error) => {
    dispatch(setLoading(false));
    throw error;
  });
};

export default slice.reducer;
