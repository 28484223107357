import React, { useEffect, Fragment } from 'react';
import {Modal, Form, InputNumber, Select, Button, Tooltip} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { calculateTotalByKilos } from './../common/utils';
import { useSelector } from 'react-redux';

const OrderCreateForm = ({ visible, onCreate, onCancel, loading, customers, selectedCode, handleCodeChange }) => {

  const [form] = Form.useForm();
  const {receptionUnits} = useSelector((state) => state.fillingOrders);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const selectedUDR = receptionUnits.find((i) => i.codeUdr === values.codeUdr);
        let kilos;
        let total;
        if(values.typePurchase === 1){
          //total, udr, kilos
          kilos = values.kilos;
          total = (selectedUDR.price - selectedUDR.discount) * values.kilos;
        }else{
          kilos = (values.monto / (selectedUDR.price - selectedUDR.discount));
          total = values.monto;
        }
        onCreate({ codeUdr: values.codeUdr, total, kilos, receipt: values.receipt, customerCode: values.customerCode });
      })
      .catch(() => {});
  };

  const ComponentExchange = ({ total }) => {
    const totalToPay = total;
    return (
      <Fragment>
        <Form.Item
          name="receipt"
          label="Recibo"
          rules={[
            { required: true },
            () => ({
              validator(rule, value) {
                if (Number.isNaN(Number(value))) {
                  return Promise.reject(new Error('Número inválido'));
                }

                if (value < totalToPay) {
                  return Promise.reject(new Error('La cantidad debe ser mayo o igual a $'.concat(total.toFixed(2))));
                }

                const decIndex = value.toString().indexOf('.');
                const decimals = decIndex >= 0 ? value.toString().substr(decIndex + 1) : '';
                if (decIndex >= 0 && (decimals.length < 1 || decimals.length > 2)) {
                  return Promise.reject(new Error('Decimales inválidos (max 2)'));
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Cambio:"
          shouldUpdate={(prevValues, currentValues) => (
            prevValues.receipt !== currentValues.receipt
          )}
        >
          {({ getFieldValue }) => {
            const receipt = getFieldValue('receipt');
            const change = receipt - totalToPay;
            if ( change >= 0) {
              return (
                <h1>{`$${change.toFixed(2)}`}</h1>
              );
            }
            return <h1>$-</h1>;
          }}
        </Form.Item>
      </Fragment>
    )
  }

  return (
    <Modal
      visible={visible}
      closable={false}
      maskClosable={false}
      title="Nueva orden"
      forceRender
      footer={[
        <Button key="cancel" loading={loading} onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="save" type="primary" loading={loading} onClick={onOk}>
          Generar orden
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="order_create_form"
        validateMessages={{
          required: 'Campo requerido',
        }}
      >
        <Form.Item
          name="customerCode"
          className={'in-select-serial-number'}
          label={(
            <span>
              Cliente&nbsp;
              <Tooltip color="black" placement="right" title={'Solo se mostrarán los clientes con especificaciones de ODL '}>
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          )}
          rules={[{ required: true }]}
          initialValue={selectedCode}
        >
          <Select
            placeholder="Selecciona ..."
            allowClear
            showSearch
            filterOption={(input, option) => (
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )}
            onChange={(value) => handleCodeChange(value)}
          >
            {
              customers.map((i) => (
                <Select.Option key={i.code} value={i.code}>
                  {`${i.code}, ${i.name}, ${i.numReceptionUnits}`}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          name="codeUdr"
          label={(
            <span>
              Condición de pago&nbsp;
              <Tooltip
                color="black"
                placement="right"
                title={
                  'Solo se mostrarán las condiciones de pago de las UDR del '
                  + 'cliente ODL001 con tipo de producto "Gas LP (Kg)" asignado.'
                }
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          )}
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Selecciona ..."
            allowClear
            showSearch
            filterOption={(input, option) => (
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )}
          >
            {
              receptionUnits.map((i) => (
                <Select.Option key={i.codeUdr} value={i.codeUdr}>
                  {i.paymentCondition}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          name="typePurchase"
          label={(
            <span>
              Tipo de venta&nbsp;
            </span>
          )}
          rules={[{ required: true }]}
          initialValue={1}
        >
          <Select>
              <Select.Option key={1} value={1}>
                Por Kilos
              </Select.Option>
              <Select.Option key={2} value={2}>
                Por Monto
              </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.typePurchase !== currentValues.typePurchase}
        >
          {({ getFieldValue }) => { 
            return getFieldValue('typePurchase') === 1 ? (
              <Form.Item
              name="kilos"
              label="Kilos"
              rules={[
                { required: true },
                () => ({
                  validator(rule, value) {
                    if (Number.isNaN(Number(value))) {
                      return Promise.reject(new Error('Número inválido'));
                    }
  
                    if (value < 0.1 || value > 50000) {
                      return Promise.reject(new Error('Rango inválido (0.10 - 50000)'));
                    }
  
                    const decIndex = value.toString().indexOf('.');
                    const decimals = decIndex >= 0 ? value.toString().substr(decIndex + 1) : '';
                    if (decIndex >= 0 && (decimals.length < 1 || decimals.length > 2)) {
                      return Promise.reject(new Error('Decimales inválidos (max 2)'));
                    }
  
                    return Promise.resolve();
                  },
                }),
              ]}
            >
                <InputNumber />
            </Form.Item>) :
            <Form.Item
              name="monto"
              label="Monto"
              rules={[
                { required: false },
                () => ({
                  validator(rule, value) {
                    if (Number.isNaN(Number(value))) {
                      return Promise.reject(new Error('Número inválido'));
                    }

                    if (value < 10 || value > 50000) {
                      return Promise.reject(new Error('Rango inválido (10 - 50000)'));
                    }

                    const decIndex = value.toString().indexOf('.');
                    const decimals = decIndex >= 0 ? value.toString().substr(decIndex + 1) : '';
                    if (decIndex >= 0 && (decimals.length < 1 || decimals.length > 2)) {
                      return Promise.reject(new Error('Decimales inválidos (max 2)'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber />
            </Form.Item>;
          }}
        </Form.Item>
        <div className='container-totals'>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.typePurchase !== currentValues.typePurchase}
          >
            {({ getFieldValue }) => {
              return getFieldValue('typePurchase') === 1 ? (<Form.Item
                label="Total:"
                shouldUpdate={(prevValues, currentValues) => (
                  prevValues.kilos !== currentValues.kilos
                  || prevValues.codeUdr !== currentValues.codeUdr
                )}
              >
                {() => {
                  const selectedUDR = receptionUnits.find((i) => i.codeUdr === getFieldValue('codeUdr'));
                  const kilos = getFieldValue('kilos');

                  if (selectedUDR && kilos && !Number.isNaN(Number(kilos))) {
                    const total = calculateTotalByKilos(selectedUDR.price, selectedUDR.discount, kilos);
                    return (
                      <h1>{`$${total.toFixed(2)}`}</h1>
                    );
                  }
                  return <h1>$-</h1>;
                }}
              </Form.Item>): (
                <Form.Item
                label="Kilos:"
                shouldUpdate={(prevValues, currentValues) => (
                  prevValues.monto !== currentValues.monto
                  || prevValues.codeUdr !== currentValues.codeUdr
                )}
              >
                {() => {
                  const selectedUDR = receptionUnits.find((i) => i.codeUdr === getFieldValue('codeUdr'));
                  const monto = getFieldValue('monto');

                  if (selectedUDR && monto && !Number.isNaN(Number(monto))) {
                    const total = (monto / (selectedUDR.price - selectedUDR.discount));
                    return (
                      <h1>{`${total.toFixed(2)} Kilos`}</h1>
                    );
                  }
                  return <h1>-Kilos</h1>;
                }}
              </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => (
              prevValues.typePurchase !== currentValues.typePurchase
              || prevValues.codeUdr !== currentValues.codeUdr
              || prevValues.monto !== currentValues.monto
              || prevValues.kilos !== currentValues.kilos
            )}
          >
            {({ getFieldValue }) => {
              const selectedUDR = receptionUnits.find((i) => i.codeUdr === getFieldValue('codeUdr'));
              if ( getFieldValue('typePurchase') === 1) {
                const kilos = getFieldValue('kilos');
                if (selectedUDR && kilos && !Number.isNaN(Number(kilos))) {
                  const total = calculateTotalByKilos(selectedUDR.price, selectedUDR.discount, kilos);
                  return (<ComponentExchange total={total}/>);
                }
                return null;

              } else if ( getFieldValue('typePurchase') === 2) {
                const monto = getFieldValue('monto');
                if (selectedUDR && monto && !Number.isNaN(Number(monto))) {
                  return (<ComponentExchange total={monto}/>);
                }
                return null;
              }
            }}
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default OrderCreateForm;
