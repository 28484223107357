import moment from 'moment';
import React from 'react';
import './styles.css';

class OrderTicket extends React.Component { // eslint-disable-line
  render() {
    const { order, generalPurpose } = this.props;

    const nowDate = moment().format('YYYY-MM-DD HH:mm');
    if (!order) {
      return (<div>---</div>);
    }

    return (
      <div className="ticket__container">
        <div className="ticket__header">
          <div className="header__brand">{generalPurpose}</div>
          <p>
            Su Consumo de Gas ENCONTROL
            <br />
            <div>{nowDate}</div>
            <br />
            Mercantil Distribuidora S.A de C.V
            <br />
            <br />
            Adolfo Lopez Mateos #320
            <br />
            Villas de Oriente
            <br />
            San Nicolas de los Garza N.L.
            <br />
            TEL.-(8118330-3030/01800TODOGAS)
          </p>
        </div>
        <div className="ticket__content">
          <div>
            Folio .............:
            <span className="info__value">{order.folioOrder}</span>
          </div>
          <div>
            KG ................:
            <span className="info__value">{order.kilos}</span>
          </div>
          <div>
            Total .............:
            <span className="info__value">{`$ ${order.total.toFixed(2)}`}</span>
          </div>
          <div>
            Efectivo ..........:
            <span className="info__value">{`$ ${order.receipt.toFixed(2)}`}</span>
          </div>
          <div>
            Cambio ............:
            <span className="info__value">{`$ ${(order.receipt.toFixed(2) - order.total.toFixed(2)).toFixed(2)}`}</span>
          </div>
        </div>
        <div className="ticket__footer" />
      </div>
    );
  }
}

export default OrderTicket;
