import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import './styles.css';

const getMaxValue = (data) => {
  const max = Math.max(...data.map((i) => i.value));
  return max > 10 ? 10 : max;
};

const NivoResponsiveBar = ({ data }) => (
  <ResponsiveBar
    data={data}
    keys={['value']}
    indexBy="id"
    margin={{
      top: 20,
      right: 20,
      bottom: 50,
      left: 30,
    }}
    padding={0.3}
    colors={(item) => item.data.color || 'hsl(212, 83%, 64%)'}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 20,
    }}
    axisLeft={{
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      tickValues: getMaxValue(data),
    }}
    tooltip={({ indexValue, value }) => (
      <strong>{`${indexValue}: ${value}`}</strong>
    )}
    enableLabel={false}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    animate
    motionStiffness={90}
    motionDamping={15}
  />
);

export default NivoResponsiveBar;
