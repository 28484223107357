import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import {
  Button,
  Table,
  Tag,
  Input,
  Popconfirm,
  Row,
  Col,
  Select,
  Tooltip,
  Card,
  Typography,
} from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  SaveOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import OrderCreateForm from '../../components/orderCreateForm';
import FinishConfirmation from '../../components/finishConfirmation';
import CancelConfirmation from '../../components/cancelConfirmation';
import OrderTicket from '../../components/orderTicket';
import {
  createOrder,
  toggleOrderForm,
  fetchOrders,
  cancelOrder,
  setFilters,
  resetFilters,
  setPagination,
  syncOrder,
  setFolioValue,
  finishOrder,
  setFinishOrderFolio,
  setOrdertoPrint,
  setCancelOrderFolio,
  toggleOrderRefund,
  fetchUpdateFillingOrderBalance,
  fetchReceptionUnits,
  fetchReceptionUnitsFailed,
  fetchCsvData,
  setCutOrdersToPrint,
} from './fillingOrdersSlice';
import { OrderColors, OrderStatus } from '../../common/constants';
import { calculateTotalByKilos, toNumberFormat } from '../../common/utils';
import { fetchCustomers } from '../../common/commonSlice';
import OrderRefundForm from '../../components/orderRefundForm';
import CutTicket from '../../components/cutTicket/index';

const { Title, Text } = Typography;

const { Search } = Input;

const FillingOrders = () => {
  const {
    loading,
    creatingOrder,
    showOrderForm,
    filters,
    orders,
    order,
    pagination,
    totals,
    folioValue,
    finishOrderFolio,
    cancelOrderFolio,
    loadingPrint,
    orderWithRefund,
    showRefundForm,
    cutToPrint,
  } = useSelector((state) => state.fillingOrders);

  /** Hook for selected customer management */
  const [selectedCustomer, setSelectedCustomer] = useState({ code: undefined, generalPurpose: '' });

  const { customers } = useSelector((state) => state.common);
  const componentToPrint = useRef();
  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  const cutTicketToPrint = useRef();
  const handlePrintCutTicket = useReactToPrint({
    content: () => cutTicketToPrint.current,
  });

  const loadOrders = useCallback(() => {
    dispatch(fetchOrders(
      pagination.page,
      pagination.limit,
      filters.folio ? { folio: filters.folio } : filters,
    ));
  }, [dispatch, pagination.page, pagination.limit, filters]);

  useEffect(() => {
    if (selectedCustomer.code === '' || selectedCustomer.code === undefined) {
      dispatch(fetchReceptionUnitsFailed());
    } else {
      dispatch(fetchReceptionUnits(selectedCustomer.code));
    }
  }, [dispatch, selectedCustomer]);

  /** Function to update the selected customer */
  const handleCustomerChange = (code) => {
    const customer = customers.find((cust) => cust.code === code);
    // Find the general purpose from selected customer. If it is not found, set a default string
    const generalPurpose = customer !== undefined && customer.generalPurpose !== '' ? customer.generalPurpose : 'Orden de llenado';
    setSelectedCustomer({ code, generalPurpose });
  };
  const handlePrintItem = (code) => {
    const precode = selectedCustomer.code;
    handleCustomerChange(code);
    handlePrint();
    handleCustomerChange(precode);
  };

  useEffect(loadOrders, [loadOrders]);

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  const onChangeFilter = (filter, value) => {
    const updatedFilters = {
      ...filters,
      [filter]: value,
    };

    dispatch(setFilters(updatedFilters));
  };

  const onChangePage = (page, size) => {
    const updatedPagination = {
      ...pagination,
      page: page - 1,
      limit: size,
    };

    dispatch(setPagination(updatedPagination));
  };

  const handleCancelOrder = (reason) => {
    dispatch(cancelOrder(cancelOrderFolio, reason))
      .then(() => {
        loadOrders();
        dispatch(setCancelOrderFolio(null));
      })
      .catch(() => dispatch(setCancelOrderFolio(null)));
  };

  const handleCreateOrder = (values) => {
    dispatch(createOrder(
      values.codeUdr,
      values.kilos,
      values.total,
      values.receipt,
      values.customerCode,
    ))
      .then(() => {
        handlePrint();
        loadOrders();
      })
      .catch(() => {});
  };

  const handleFinishOrder = (reason) => {
    dispatch(finishOrder(finishOrderFolio, reason))
      .then(() => {
        loadOrders();
        dispatch(setFinishOrderFolio(null));
      })
      .catch(() => dispatch(setFinishOrderFolio(null)));
  };

  const handleRefundOrder = (values) => {
    dispatch(fetchUpdateFillingOrderBalance(values))
      .then(() => {
        dispatch(toggleOrderRefund({ order: {}, isVisible: false }));
        loadOrders();
      })
      .catch(() => {});
  };

  const renderNewActions = (record) => (
    <div>
      <Popconfirm
        title="¿Estás seguro de CANCELAR la orden?"
        okText="Sí"
        cancelText="No"
        onConfirm={() => {
          dispatch(setCancelOrderFolio(record.orderFolio));
        }}
      >
        <Button type="link">Cancelar</Button>
      </Popconfirm>
      <Button
        type="link"
        loading={loadingPrint}
        onClick={() => {
          dispatch(
            setOrdertoPrint({
              folioOrder: record.orderFolio,
              kilos: record.authorizedKilos,
              price: record.price,
              discount: record.discount,
              receipt: record.receipt,
            }),
          )
            .then(() => {
              handlePrintItem(record.customerCode);
            });
        }}
      >
        Imprimir
      </Button>
    </div>
  );

  const renderRequiredActions = (record) => (
    <div>
      <Popconfirm
        title="¿Estás seguro de Sincronizar la orden?"
        okText="Sí"
        cancelText="No"
        onConfirm={() => {
          dispatch(syncOrder(record.orderFolio))
            .then(() => loadOrders())
            .catch(() => {});
        }}
      >
        <Button type="link">Sincronizar</Button>
      </Popconfirm>
      {record.kilosFilled === 0 && (
        <Popconfirm
          title="¿Estás seguro de CANCELAR la orden?"
          okText="Sí"
          cancelText="No"
          onConfirm={() => {
            const asyncAction = async () => {
              try {
                await dispatch(syncOrder(record.orderFolio));
                await loadOrders();
                if (record.kilosFilled === 0) {
                  console.log(record.kilosFilled , "FINE");
                  await dispatch(setCancelOrderFolio(record.orderFolio));
                  await loadOrders();
                }
              } catch (err) {
                console.log(record.kilosFilled , "ERROR");
                await dispatch(setCancelOrderFolio(record.orderFolio));
                await loadOrders();
              }
              
            };
            asyncAction();
          }}
        >
          <Button type="link">Cancelar</Button>
        </Popconfirm>
      )}
    </div>
  );

  const renderPartialActions = (record) => (
    <div>
      { record.customerBalance === 0 && (
      <Button
        type="link"
        onClick={() => dispatch(toggleOrderRefund({ order: record, isVisible: true }))}
      >
        Devolver
      </Button>
      )}
    </div>
  );

  const renderTotal = (text, record) => {
    const total = calculateTotalByKilos(record.price, record.discount, record.authorizedKilos);

    if (record.status === OrderStatus.CANCELADA) {
      return (
        <div>
          <span style={{ textDecorationLine: 'line-through', color: 'lightcoral' }}>
            {`$ ${total.toFixed(2)}`}
          </span>
          <span style={{ marginLeft: '5px' }}>$ 0.00</span>
          <Tooltip title={record.causeOfTermination} placement="right">
            <WarningOutlined style={{ fontSize: '20px', color: 'red', marginLeft: '5px' }} />
          </Tooltip>
        </div>
      );
    }
    if (record.status === OrderStatus.DEVOLUCION) {
      return (
        <div>
          <span style={{ textDecorationLine: 'line-through', color: 'lightcoral' }}>
            {`$ ${record.total.toFixed(2)}`}
          </span>
          <span style={{ marginLeft: '5px' }}>{`$ ${(record.total - record.customerBalance).toFixed(2)}`}</span>
          {// <Tooltip title={record.causeOfTermination} placement="right">
            // <WarningOutlined style={{ fontSize: '20px', color: 'red', marginLeft: '5px' }} />
          // </Tooltip>
          }
        </div>
      );
    }

    if (record.causeOfTermination) {
      const totalFilled = (record.price - record.discount) * record.kilosFilled;
      return (
        <div>
          <span style={{ textDecorationLine: 'line-through', color: 'lightcoral' }}>
            {`$ ${total.toFixed(2)}`}
          </span>
          <span style={{ marginLeft: '5px' }}>
            {`$ ${totalFilled.toFixed(2)}`}
          </span>
          <Tooltip title={record.causeOfTermination} placement="right">
            <WarningOutlined style={{ fontSize: '20px', color: 'orange', marginLeft: '5px' }} />
          </Tooltip>
        </div>
      );
    }

    return `$ ${total.toFixed(2)}`;
  };

  /* Function commented because button functionality has changed to download cut ticket
  But csv can be requested again in the future

  const handleCsvDownload = (generateCSV) => {
    dispatch(fetchCsvData(filters.folio ? { folio: filters.folio } : filters))
      .then((data) => {
        generateCSV(data, columnsDataCSV);
      }).catch((error) => {
        throw error;
      });
  };
  */

  const handleCutTicketDownload = async () => {
    const fetchedData = await dispatch(fetchCsvData(filters));
    await dispatch(setCutOrdersToPrint(fetchedData));
    await handlePrintCutTicket();
  };

  const columns = [
    {
      title: 'Folio',
      dataIndex: 'orderFolio',
      key: 'orderFolio',
      render: (text) => <Link to={`/orders/${text}`}>{text}</Link>,
    },
    {
      title: 'Cliente',
      dataIndex: 'customerCode',
      key: 'customerCode',
    },
    {
      title: 'Kilos autorizados',
      dataIndex: 'authorizedKilos',
      key: 'authorizedKilos',
      responsive: ['lg'],
    },
    {
      title: 'Kilos llenados',
      dataIndex: 'kilosFilled',
      key: 'kilosFilled',
      responsive: ['lg'],
    },
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      responsive: ['md'],
      render: renderTotal,
    },
    {
      title: 'Saldo',
      key: 'balance',
      dataIndex: 'balance',
    },
    {
      title: 'Monto devuelto',
      key: 'customerBalance',
      dataIndex: 'customerBalance',
      responsive: ['md'],
      render: (customerBalance) => {
        if (customerBalance !== null) {
          return `$ ${customerBalance.toFixed(2)}`;
        }
        return customerBalance;
      },
    },
    {
      title: 'Estatus',
      key: 'status',
      dataIndex: 'status',
      responsive: ['sm'],
      render: (text, record) => (
        <Tag color={OrderColors[record.status]}>{text}</Tag>
      ),
    },
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['sm'],
      render: (text) => moment(text).format('DD/MM/YYYY - hh:mm a'),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (text, record) => {
        if (record.status === OrderStatus.NUEVA) {
          return renderNewActions(record);
        }
        if (record.status === OrderStatus.SOLICITADA) {
          return renderRequiredActions(record);
        }
        if (record.status === OrderStatus.PARCIAL) {
          return renderPartialActions(record);
        }
        return <div />;
      },
    },
  ];

  return (
    <div>
      <Row gutter={16} style={{ paddingBottom: '.3rem' }}>
        <Col span={4}>
          <Card
            title={<Title level={4}><Text style={{ color: '#1890ff' }}>FOLIOS</Text></Title>}
            bordered={false}
            style={{
              background: '#e6f7ff',
              borderRadius: '.5rem',
              textAlign: 'center',
              height: '8.5rem',
            }}
          >
            <Title level={2} style={{ color: '#1d39c4' }}>{totals ? totals.folios : 0}</Title>
          </Card>
        </Col>
        <Col span={5}>
          <Card
            title={<Title level={4}><Text style={{ color: '#1890ff' }}>KILOS LLENADOS</Text></Title>}
            bordered={false}
            style={{
              background: '#bae7ff',
              borderRadius: '.5rem',
              textAlign: 'center',
              height: '8.5rem',
            }}
          >
            <Title level={2} style={{ color: '#1d39c4' }}>{`${totals ? toNumberFormat(totals.totalKgFilling) : 0} Kg`}</Title>
          </Card>
        </Col>
        <Col span={5}>
          <Card
            title={<Title level={4}><Text style={{ color: '#1890ff' }}>TOTAL EN CAJA</Text></Title>}
            bordered={false}
            style={{
              background: '#91d5ff',
              borderRadius: '.5rem',
              textAlign: 'center',
              height: '8.5rem',
            }}
          >
            <Title level={2} style={{ color: '#1d39c4' }}>{`$${totals ? toNumberFormat(totals.total) : 0}`}</Title>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col flex="auto" className="filters__item">
          <Select
            placeholder="Selecciona ..."
            allowClear
            showSearch
            filterOption={(input, option) => (
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )}
            onChange={(value) => handleCustomerChange(value)}
            value={selectedCustomer.code}
            className="filters__input"
          >
            {
              customers.map((i) => (
                <Select.Option key={i.code} value={i.code}>
                  {`${i.code}, ${i.name}, ${i.numReceptionUnits}`}
                </Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col flex="auto" className="filters__item">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => dispatch(toggleOrderForm(true))}
            disabled={selectedCustomer.code === undefined}
          >
            Crear orden
          </Button>
        </Col>
        <Col className="filters__item" flex="50px">
          <Tooltip title="Descargar ticket de corte">
            <Button
              icon={<SaveOutlined />}
              className="filters__input"
              onClick={handleCutTicketDownload}
            />
          </Tooltip>
        </Col>
        <Col className="filters__item" flex="50px">
          <Tooltip title="Actualizar información">
            <Button
              icon={<ReloadOutlined />}
              onClick={loadOrders}
              className="filters__input"
            />
          </Tooltip>
        </Col>
        <Col xs={24} sm={12} md={5} lg={4} xl={4} className="filters__item">
          <Search
            placeholder="Folio"
            onChange={(e) => dispatch(setFolioValue(e.target.value))}
            onSearch={(value) => onChangeFilter('folio', value)}
            value={folioValue}
            className="filters__input"
            enterButton
            allowClear
          />
        </Col>
        <Col xs={24} sm={12} md={10} lg={4} xl={4} className="filters__item">
          <Select
            placeholder="Estatus"
            className="filters__input"
            allowClear
            value={filters.status}
            onChange={(value) => onChangeFilter('status', value || null)}
            disabled={filters.folio}
          >
            {Object.values(OrderStatus).map((val) => (
              <Select.Option key={val} value={val}>{val}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col className="filters__item" flex="120px">
          <Button
            type="link"
            onClick={() => dispatch(resetFilters())}
          >
            Limpiar filtros
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        rowKey="orderFolio"
        pagination={{
          current: pagination.page + 1,
          total: pagination.totalItems,
          onChange: onChangePage,
          showSizeChanger: true,
          onShowSizeChange: onChangePage,
          pageSizeOptions: [10, 20, 50],
          pageSize: pagination.limit,
        }}
      />
      <OrderCreateForm
        visible={showOrderForm}
        loading={creatingOrder}
        onCreate={handleCreateOrder}
        onCancel={() => dispatch(toggleOrderForm(false))}
        customers={customers}
        selectedCode={selectedCustomer.code}
        handleCodeChange={handleCustomerChange}
      />
      <FinishConfirmation
        visible={!!finishOrderFolio}
        loading={loading}
        onOk={handleFinishOrder}
        onCancel={() => dispatch(setFinishOrderFolio(null))}
      />
      <CancelConfirmation
        visible={!!cancelOrderFolio}
        loading={loading}
        onOk={handleCancelOrder}
        onCancel={() => dispatch(setCancelOrderFolio(null))}
      />
      <OrderRefundForm
        visible={showRefundForm}
        order={orderWithRefund}
        loading={loading}
        onCreate={handleRefundOrder}
        onCancel={() => dispatch(toggleOrderRefund({ order: {}, isVisible: false }))}
      />
      <div style={{ display: 'none' }}>
        <OrderTicket
          ref={componentToPrint}
          order={order}
          generalPurpose={selectedCustomer.generalPurpose}
        />
      </div>
      <div style={{ display: 'none' }}>
        <CutTicket ref={cutTicketToPrint} orders={cutToPrint} />
      </div>
    </div>
  );
};

export default FillingOrders;
