import React, { useEffect } from 'react';
import { Modal, Button, Descriptions, Form, InputNumber, Divider, Input } from 'antd';
import moment from 'moment';

const OrderRefundForm = ({
  visible, order, loading, onCreate, onCancel,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const onOk = () => {
    form.validateFields()
      .then((values) => onCreate({ ...values, folio: order.orderFolio }))
      .catch(() => {});
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      markClosable={false}
      title={`Devolución de orden ${order.orderFolio}`}
      forceRender
      footer={[
        <Button key="cancel" loading={loading} onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="save" type="primary" loading={loading} onClick={onOk}>
          Guardar
        </Button>,
      ]}
    >
      <div>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Cliente">{order.customerCode}</Descriptions.Item>
          <Descriptions.Item label="Fecha">{moment(order.createdAt).format('DD/MM/YYYY - hh:mm a')}</Descriptions.Item>
          <Descriptions.Item label="Código UDR">{order.receptionUnit}</Descriptions.Item>
          <Descriptions.Item label="Condición de pago">{order.paymentCondition}</Descriptions.Item>
        </Descriptions>
        <Divider />
        Campos marcados con * son requeridos.
        <Form form={form} layout="vertical" name="refund_order_form" validateMessages={{ required: 'Campo requerido' }}>
          <Form.Item
            name="saleFolio"
            label="Folio de la venta  si es mas de una venta separalo por comas"
            style={{ marginTop: '10px' }}
          >
            <Input
              autoFocus
              style={{ width: '50%' }}
            />
          </Form.Item>
          <Form.Item name="balance" label="Monto a devolver:" rules={[{ required: true }]}>
            <InputNumber
              min={0}
              autoFocus
              style={{ width: '50%' }}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default OrderRefundForm;
