import React from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../../assets/images/Logo-Gaspar-G4S.png';
import './styles.css';

const LoginForm = ({ onSubmit, invalidLogin, loading }) => {

  const onFinish = (values) => {
    onSubmit(values);
  };

  return (
    <div className="container">
      <img src={logo} className="form__logo" alt="logo" />
      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        validateMessages={{
          required: 'Campo requerido',
        }}
      >
        <Form.Item name="email" rules={[{ required: true }]}>
          <Input prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true }]}>
          <Input
            prefix={<LockOutlined />}
            type="password"
            placeholder="Contraseña"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="form__submit"
            loading={loading}
          >
            Iniciar sesión
          </Button>
        </Form.Item>
      </Form>
      {invalidLogin && <Alert message="Email o contraseña incorrectos" type="error" />}
    </div>
  );
};

export default LoginForm;
