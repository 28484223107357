import React, { useEffect } from 'react';
import {
  Modal, Input, Button, Form,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './styles.css';

const { TextArea } = Input;

const CancelConfirmation = ({
  visible,
  loading,
  onCancel,
  onOk,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onOk(values.reason);
      })
      .catch(() => {});
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      loading={loading}
      forceRender
      footer={[
        <Button key="cancel" loading={loading} onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="save" type="primary" loading={loading} onClick={handleOk}>
          Aceptar
        </Button>,
      ]}
    >
      <div className="container__title">
        <ExclamationCircleOutlined className="title__icon" />
        <span className="title__text">
          ¿Cual es el motivo para CANCELAR la orden?
        </span>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="cancel_confirmation_form"
        validateMessages={{ required: 'Campo requerido' }}
      >
        <Form.Item
          name="reason"
          rules={[{ required: true }]}
        >
          <TextArea
            rows={4}
            maxLength={200}
            autoSize={{ minRows: 4, maxRows: 6 }}
            placeholder="Escribe el motivo"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CancelConfirmation;
