import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import MainLayout from './components/layout';
import FillingOrders from './routes/fillingOrders';
import OrderDetails from './routes/orderDetails';
import Login from './routes/login';
import Dashboard from './routes/dashboard';
import { isAuthenticated } from './common/localStorage';

const MainLayoutRoute = ({
  component: Component,
  contentStyle,
  title,
  exact,
  path,
}) => (
  <Route
    exact={exact}
    path={path}
    render={() => {
      if (!isAuthenticated()) {
        return <Redirect to={{ pathname: '/login' }} />;
      }

      return (
        <MainLayout title={title} contentStyle={contentStyle}>
          <Component />
        </MainLayout>
      );
    }}
  />
);

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/login"><Login /></Route>

      <MainLayoutRoute exact path="/orders" component={FillingOrders} title="Llenado de cilindros" />
      <MainLayoutRoute exact path="/orders/:orderFolio" component={OrderDetails} title="Detalle de orden" />
      <MainLayoutRoute path="/" component={Dashboard} title="Tablero" contentStyle={{ background: '#f0f2f5' }} />
    </Switch>
  </Router>
);

export default Routes;
