export const OrderStatus = {
  NUEVA: 'NUEVA',
  PRESOLICITADA: 'PRESOLICITADA',
  SOLICITADA: 'SOLICITADA',
  TERMINADA: 'TERMINADA',
  PARCIAL: 'PARCIAL',
  CANCELADA: 'CANCELADA',
  DEVOLUCION: 'DEVOLUCION',
};

export const OrderColors = {
  NUEVA: 'geekblue',
  PRESOLICITADA: 'magenta',
  SOLICITADA: 'purple',
  TERMINADA: 'green',
  PARCIAL: 'orange',
  CANCELADA: 'red',
};

export const OrderAcronym = {
  NUEVA: 'N',
  PRESOLICITADA: 'PS',
  SOLICITADA: 'S',
  TERMINADA: 'T',
  PARCIAL: 'P',
  CANCELADA: 'C',
  DEVOLUCION: 'D',
};

export const OrderColorsHex = {
  NUEVA: {
    colorName: 'geekblue', color: '#2f54eb', background: '#f0f5ff', borderColor: '#adc6ff',
  },
  PRESOLICITADA: {
    colorName: 'magenta', color: '#eb2f96', background: '#fff0f6', borderColor: '#ffadd2',
  },
  SOLICITADA: {
    colorName: 'purple', color: '#722ed1', background: '#f9f0ff', borderColor: '#d3adf7',
  },
  TERMINADA: {
    colorName: 'green', color: '#52c41a', background: '#f6ffed', borderColor: '#b7eb8f',
  },
  PARCIAL: {
    colorName: 'orange', color: '#fa8c16', background: '#fff7e6', borderColor: '#ffd591',
  },
  CANCELADA: {
    colorName: 'red', color: '#f5222d', background: '#fff1f0', borderColor: '#ffa39e',
  },
  DEVOLUCION: {
    colorName: 'black', color: '#000000', background: '#fff1f0', borderColor: '#000000',
  },
};
