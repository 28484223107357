export const removeEmptyValues = (obj) => {
  const res = {};

  Object.keys(obj).forEach((k) => {
    if (obj[k] !== null && obj[k] !== undefined && obj[k] !== '') {
      res[k] = obj[k];
    }
  });

  return res;
};

export const getOrderTotal = (order) => {
  const total = (order.price - order.discount) * order.authorizedKilos;

  if (order.status === 'CANCELADA') {
    return 0;
  }

  if (order.causeOfTermination) {
    const totalFilled = (order.price - order.discount) * order.kilosFilled;
    return totalFilled;
  }

  return total;
};

export const calculateTotalByKilos = (price, discount, kilos) => {
  return (price - discount) * kilos;
};

export const toNumberFormat = (value) => {
  if (value === null || value === undefined) {
    return '0.00';
  }

  if (value === 0 || value === 0.0) {
    return 0;
  }
  if (value || value === 0 || value === 0.0) {
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  return '';
};
