let apiUrl;
let port;

switch (process.env.REACT_APP_STAGE) {
  case 'production':
    apiUrl = 'http://72.249.170.215:8900';
    break;
  case 'staging':
    apiUrl = 'http://108.161.139.219:8890';
    break;
  default:
    apiUrl = 'http://localhost:8080';
}

export const API_URL = apiUrl;
export const PORT = port;
