import React from 'react';
import { Card, Spin, Empty } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import './styles.css';

const ChartContainer = ({
  children,
  loading,
  empty,
  title,
}) => (
  <Card className="chart-container__card" title={title}>
    <Spin
      spinning={loading}
      indicator={<Loading3QuartersOutlined style={{ fontSize: 24 }} spin />}
    >
      <div className="chart-container__body">
        {empty && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="chart-container__empty" />}
        {!empty && !loading && children}
      </div>
    </Spin>
  </Card>
);

export default ChartContainer;
