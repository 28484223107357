import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { notificationError } from '../../common/errors';
import FillingOrdersApi from '../../services/fillingOrdersApi';
import { getUserData } from '../../common/localStorage';

const defaultFilters = {
  date: moment().format('YYYY-MM-DD'),
};

export const slice = createSlice({
  name: 'dashboard',
  initialState: {
    orders: [],
    filters: { ...defaultFilters },
    loading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    fetchOrdersSuccess: (state, action) => {
      state.orders = action.payload.items;
    },
  },
});

export const {
  setLoading, fetchOrdersSuccess, setFilters,
} = slice.actions;

export const fetchOrders = (page, pageSize, filters) => (dispatch) => {
  dispatch(setLoading(true));
  return FillingOrdersApi.getOrders(page, pageSize, { ...filters, idUser: getUserData.idUser})
    .then((data) => {
      dispatch(fetchOrdersSuccess(data));
      dispatch(setLoading(false));
    })
    .catch((error) => {
      notificationError(error);
      dispatch(setLoading(false));
    });
};

export default slice.reducer;
